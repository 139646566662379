@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  font-family: sans-serif;
  text-align: center;
}

.sortField {
  color: #c00c00;
}

.table-1 {
  border-collapse: collapse;
  @apply w-full;
}

.table-1 td,
.table-1 th {
  @apply p-2 border text-center;
}

.table-1 th {
  @apply bg-gray-300 font-bold;
}
.table-1 tr:nth-child(2n) {
  @apply bg-gray-100;
}

.table-1 tr:hover {
  @apply bg-yellow-50;
}

.admin:nth-child(2n) {
  @apply bg-gray-100;
}
